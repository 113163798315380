import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { changeLocale } from 'gatsby-plugin-intl';
import _find from 'lodash/find';

import OutsideClickHandler from 'react-outside-click-handler';

import config from 'config';

import './LangSwitcher.scss';

const LangSwitcher = ({ currentLang }) => {
  const [opened, setOpened] = useState(false);
  const selectedLang = _find(config.availableLanguages, { id: currentLang });

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpened(false);
      }}
    >
      <div
        className="language-picker"
        onClick={() => {
          setOpened(!opened);
        }}
      >
        {selectedLang.name}
        {
            opened && (
              <div className="options-wrapper">
                {
                    config.availableLanguages.map((el) => (
                      <div
                        className={classnames('option', {
                          selected: el.id === currentLang,
                        })}
                        onClick={() => {
                          changeLocale(el.id);
                        }}
                        key={el.id}
                      >
                        {el.name}
                      </div>
                    ))
                }
              </div>
            )
        }
      </div>
    </OutsideClickHandler>
  );
};

LangSwitcher.propTypes = {
  currentLang: PropTypes.string.isRequired,
};

export default LangSwitcher;
