import React from 'react';

import 'utils/normalize.css';
import 'utils/global.scss';

import Providers from 'utils/Providers';

const wrapPageElement = ({ element, props }) => (
  <Providers {...props}>
    {element}
  </Providers>
);

export {
  wrapPageElement,
};
