import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'gatsby-plugin-intl';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import SnackbarProvider from 'react-simple-snackbar';

import config from 'config';

import PageLayout from 'components/PageLayout';

const Providers = (props) => {
  const intl = props.pageContext.intl;

  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLocale}
      messages={intl.messages}
    >
      <SnackbarProvider>
        <PayPalScriptProvider
          options={{
            'client-id': config.paypalClientId,
            currency: config.currency,
          }}
        >
          <PageLayout currentLang={props.pageContext.language} path={intl.originalPath}>
            {props.children}
          </PageLayout>
        </PayPalScriptProvider>
      </SnackbarProvider>
    </IntlProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object, // eslint-disable-line
};

Providers.defaultProps = {
  children: null,
};

export default Providers;
