// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-configurator-order-approved-js": () => import("./../../../src/pages/configurator/order-approved.js" /* webpackChunkName: "component---src-pages-configurator-order-approved-js" */),
  "component---src-pages-configurator-step-1-js": () => import("./../../../src/pages/configurator/step-1.js" /* webpackChunkName: "component---src-pages-configurator-step-1-js" */),
  "component---src-pages-configurator-step-2-js": () => import("./../../../src/pages/configurator/step-2.js" /* webpackChunkName: "component---src-pages-configurator-step-2-js" */),
  "component---src-pages-configurator-step-3-js": () => import("./../../../src/pages/configurator/step-3.js" /* webpackChunkName: "component---src-pages-configurator-step-3-js" */),
  "component---src-pages-configurator-step-4-js": () => import("./../../../src/pages/configurator/step-4.js" /* webpackChunkName: "component---src-pages-configurator-step-4-js" */),
  "component---src-pages-configurator-step-5-js": () => import("./../../../src/pages/configurator/step-5.js" /* webpackChunkName: "component---src-pages-configurator-step-5-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-popup-acoustic-norm-js": () => import("./../../../src/pages/popup/acoustic-norm.js" /* webpackChunkName: "component---src-pages-popup-acoustic-norm-js" */),
  "component---src-pages-popup-tests-js": () => import("./../../../src/pages/popup/tests.js" /* webpackChunkName: "component---src-pages-popup-tests-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-projects-executed-js": () => import("./../../../src/pages/projects-executed.js" /* webpackChunkName: "component---src-pages-projects-executed-js" */),
  "component---src-pages-regulation-js": () => import("./../../../src/pages/regulation.js" /* webpackChunkName: "component---src-pages-regulation-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */)
}

