import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import classnames from 'classnames';

import { FormattedMessage } from 'gatsby-plugin-intl';

import SocialMedia from 'components/SocialMedia';

import 'intersection-observer';

import './PageLayout.scss';
import LangSwitcher from '../LangSwitcher';

const menuItems = [{
  id: 'products',
  url: '/products',
  messageId: 'products',
}, {
  id: 'configurator',
  url: '/configurator/step-1',
  messageId: 'configurator',
  hideOnMobile: true,
}, {
  id: 'projects',
  url: '/projects-executed',
  messageId: 'realizations',
}, {
  id: 'support',
  url: '/support',
  messageId: 'support',
}, {
  id: 'aboutUs',
  url: '/about-us',
  messageId: 'aboutUs',
}, {
  id: 'contact',
  url: '/contact',
  messageId: 'contact',
}];

const footerLinks = [{
  id: '1',
  name: 'regulation',
  url: '/regulation',
}, {
  id: '2',
  name: 'rodo',
  url: '/rodo',
}, {
  id: '3',
  name: 'impressum',
  url: '/impressum',
}];

const getMenu = (currentLang, additionalClass) => (
  <div className={`menu ${additionalClass}`}>
    <ul>
      {
          menuItems.map((el) => (
            <li
              key={el.id}
              className={classnames({
                'mobile-hidden': el.hideOnMobile,
              })}
            >
              <a href={`/${currentLang}${el.url}`}>
                <FormattedMessage id={el.messageId} />
              </a>
            </li>
          ))
      }
      <li className="lang-switcher big-line">
        <LangSwitcher currentLang={currentLang} />
      </li>
    </ul>
  </div>
);

const PageLayout = ({ currentLang, children, path }) => {
  const [scrollPosition, setScrollPosition] = useState(typeof window !== 'undefined' ? window.scrollY : 0);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      setScrollPosition(-currPos.y);
    },
    [scrollPosition],
  );

  const isHeaderFixed = scrollPosition > 200;
  const isFrontPage = path === '/';

  return (
    <div className={classnames('page-layout-container', {
      'header-fixed': isHeaderFixed,
    })}
    >
      <div className={classnames('header', {
        fixed: isHeaderFixed,
      })}
      >
        <div className="header-inner">
          <a className="not-animated logo-link" href={`/${currentLang}/`}>
            <div className={classnames('logo', {
              line: !isHeaderFixed && isFrontPage,
              notFrontPage: !isFrontPage,
            })}
            >
              <img src="/images/eho.svg" alt="eho" />
            </div>
          </a>
          {
            getMenu(currentLang, 'desktop')
          }
          <div className="visible-phone phone-menu ">
            <a
              onClick={() => {
                setMobileMenuOpened(!mobileMenuOpened);
              }}
              className={classnames('not-animated', {
                opened: mobileMenuOpened,
              })}
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </a>
          </div>
        </div>
      </div>
      <div className={classnames('mobile-menu', {
        visible: mobileMenuOpened,
      })}
      >
        {
          getMenu(currentLang, '')
        }
      </div>
      <div className="content">
        {children}
      </div>
      <div className="footer">
        <div className="footer-media">
          <SocialMedia />
        </div>
        <div className="footer-links">
          <div className="copyright">
            <a href="http://www.otwarte.com.pl" target="_blank" rel="noreferrer" className="not-animated">
              <FormattedMessage id="designBy" />
              <img src="/images/x.png" alt="Studio Otwarte" />
            </a>
          </div>
          <div className="links-wrapper">
            {
              footerLinks.map((link) => (
                <a
                  href={`/${currentLang}${link.url}`}
                  key={link.id}
                >
                  <FormattedMessage id={link.name} />
                </a>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  currentLang: PropTypes.oneOf(['en', 'pl', 'de']).isRequired,
  path: PropTypes.string.isRequired,
};

export default PageLayout;
