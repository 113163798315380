import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import config from 'config';

import './SocialMedia.scss';

const SocialMedia = ({ withArrow }) => (
  <div className="social-media">
    {withArrow && (
    <div className="arrow bounce">
      <a href="#section2">
        <img src="/images/arrow.svg" alt="arrow" />
      </a>
    </div>
    )}
    <div className="always-inline">
      <div className="media">
        <a href={config.facebook} target="_blank" rel="noreferrer">
          <FormattedMessage id="facebook" />
        </a>
      </div>
      <div className="big-line" />
      <div className="media">
        <a href={config.instagram} target="_blank" rel="noreferrer">
          <FormattedMessage id="instagram" />
        </a>
      </div>
    </div>
  </div>
);

SocialMedia.propTypes = {
  withArrow: PropTypes.bool,
};

SocialMedia.defaultProps = {
  withArrow: false,
};

export default SocialMedia;
