export default {
  contact: {
    name: 'Paweł Małek',
    phone: '+48 603 342 200',
    mail: 'office@ehomuteboards.com',
    forInvestorsMail: 'investorrelations@ehomuteboards.com',
  },
  supportContact: {
    name: 'Paweł Małek',
    phone: '+48 603 342 200',
    mail: 'support@ehomuteboards.com',
  },
  address: {
    street: 'ul. Kalwaryjska 69/9',
    city: '30-504 Kraków',
  },
  supportAddress: {
    street: 'ul. Kalwaryjska 69/9',
    city: '30-504 Kraków',
  },
  deliveryPrice: 0,
  realizationDays: 7,
  currency: 'EUR',
  panelTypes: [{
    id: '3',
    name: 'Syntax A',
    groupId: 'Syntax',
    image: 'panel-type-3.png',
    panelId: 5904335215878,
    prices: [{
      min: 1,
      max: 12,
      price: 545,
    }],
  }, {
    id: '1',
    name: 'Formula A',
    groupId: 'Formula',
    image: 'panel-type-1.png',
    panelId: 5904335215854,
    prices: [{
      min: 1,
      max: 12,
      price: 450,
    }],
  }, {
    id: '2',
    name: 'Formula B',
    groupId: 'Formula',
    image: 'panel-type-2.png',
    panelId: 5904335215861,
    prices: [{
      min: 1,
      max: 12,
      price: 450,
    }],
  }],
  paypalClientId: 'AVDWSYKvlIYq7nUAPMqMN91x9_th50yVMlK2x51H7GC35wKwlWhkM821gLXnx1QfcWbgsbVafsauUWR2',
  availableLanguages: [{
    id: 'en',
    name: 'EN',
  }, {
    id: 'de',
    name: 'DE',
  }, {
    id: 'pl',
    name: 'PL',
  }],
  instagram: 'https://www.instagram.com/ehomuteboards/',
  facebook: 'https://www.facebook.com/Eho-muteboards-109311144702621',
};
